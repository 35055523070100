import { Canvas } from '@react-three/fiber';
import './lightShow.css';
import Sphere from './Model3D/Sphere';
import Scene from './Scene/Scene';
import HDREnvironment from './HDR/HDREnvironment';
import { useTheme } from '../ThemeProvider';
import { isVisible, reflow } from "../../utils/transition";
import { Transition } from "react-transition-group";
import { Fragment, Suspense } from "react";
import prerender from "../../utils/prerender";

const LightShow = ({ id, visible, sectionRef, loadingProgress }) => {

  const theme = useTheme();
  const { themeId } = theme;
  const titleId = `${id}-title`;

  const fog = {
    lightTheme: ['#7192c2', 0, 90],
    darkTheme: ['#222831', 0, 90],
  };

  const canvasColor = {
    lightTheme: '#7192c2',
    darkTheme: '#222831',
  };

  const onEnter = () => {
    //console.log('entered');
  };

  return (
    <div
      className={'lightShow'}
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <Fragment>
            {!prerender && (
              <Suspense fallback={null}>
                {isVisible(status) && (
                  onEnter()
                )}
                <Canvas
                  shadows
                  camera={{ position: [0, 12, 20], near: 0.1, far: 100 }}
                  onCreated={({ gl }) => {
                    gl.setClearColor(
                      themeId === 'light' ? canvasColor.lightTheme : canvasColor.darkTheme
                    );
                  }}
                >
                  <fog
                    attach="fog"
                    args={themeId === 'light' ? fog.lightTheme : fog.darkTheme}
                    near={1}
                    far={80}
                  />
                  <ambientLight intensity={0.2} />
                  <Scene />
                  <Sphere loadingProgress={loadingProgress} />
                  <HDREnvironment themeId={themeId} />
                </Canvas>
              </Suspense>
            )}
          </Fragment>
        )}
      </Transition>
    </div>
  );
};

export default LightShow;
