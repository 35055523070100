import { Fragment, Suspense } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import Link from 'components/Link';
import { Button } from 'components/Button';
import DecoderText from 'components/DecoderText';
import Divider from 'components/Divider';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import './Profile.css';
import SecondaryPortrait from '../SecondaryPortrait/SecondaryPortrait';

const ProfileText = ({ status, titleId }) => (
  <Fragment>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="Greetings!" start={status !== 'exited'} delay={500} />
    </Heading>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      I am Toma and I am a Software Engineering graduate from{' '}
      <Link
        href="https://www.linkedin.com/school/via-university-college/"
        target="_blank"
      >
        VIA University College
      </Link>
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      I am curious by nature and I feel I perform best when I am thrown into new
      challenges and exciting projects. Throughout the coursework of my studies, I have
      gained experience and knowledge in a wide range of technologies, tools, and
      methodologies used in software development. You can find out more about my skills
      and competences on the <Link href="/projects/dtt">Technology stack</Link> and{' '}
      <Link href="/uses">More about me</Link> page.
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      In my spare time, I like to produce{' '}
      <Link href="https://soundcloud.com/tomachan" target="_blank">
        electronic music
      </Link>{' '}
      and experiment with new tech. I’m always interested in new projects, so feel free to
      drop me a line.
    </Text>
  </Fragment>
);

const Profile = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="profile__content">
            <div className="profile__column">
              <div className="profile__tag" aria-hidden>
                <Divider
                  notchWidth="64px"
                  notchHeight="8px"
                  collapsed={status !== 'entered'}
                  collapseDelay={1000}
                />
                <div
                  className={classNames(
                    'profile__tag-text',
                    `profile__tag-text--${status}`
                  )}
                >
                  About Me
                </div>
              </div>
              <ProfileText status={status} titleId={titleId} />
              <Button
                secondary
                className={classNames('profile__button', `profile__button--${status}`)}
                href="/contact"
                icon="send"
              >
                Send me a message
              </Button>
            </div>
            <div className="profile__column">
              <div className="profile__image-wrapper">
                {/*
                  !prerender && (
                    <Suspense fallback={null}>
                      <Portrait
                        className={classNames(
                          'profile__image',
                          `profile__image--${status}`
                        )}
                        delay={100}
                      />
                    </Suspense>
                  )*/}
                {!prerender && (
                  <Suspense fallback={null}>
                    <SecondaryPortrait
                      className={classNames(
                        'profile__image',
                        `profile__image--${status}`
                      )}
                      delay={3000}
                    />
                  </Suspense>
                )}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Profile;
