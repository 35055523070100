import { useEffect, useMemo } from 'react';
import { PMREMGenerator, UnsignedByteType } from 'three';
import { useThree } from '@react-three/fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import HDR from '../../../assets/hdr-environments/portrait-env.hdr';

// or pass as a prop / import from project assets..
// for more env maps visit:
// https://polyhaven.com

const canvasColor = {
  lightTheme: '#7192c2',
  darkTheme: '#222831',
};

function HDREnvironment({ themeId }) {
  const { gl, scene } = useThree();

  const pmRemGenerator = useMemo(() => {
    return new PMREMGenerator(gl);
  }, [gl]);

  const loader = useMemo(() => {
    return new RGBELoader();
  }, []);

  loader.setDataType(UnsignedByteType);
  pmRemGenerator.compileEquirectangularShader();

  useEffect(() => {
    if (themeId === 'light') {
      loader.load(HDR, texture => {
        scene.environment = pmRemGenerator.fromEquirectangular(texture).texture;
        // one can also set scene.background to envMap here

        texture.dispose();
        pmRemGenerator.dispose();

        gl.setClearColor(canvasColor.lightTheme);
      });
    } else {
      scene.environment = null;
      gl.setClearColor(canvasColor.darkTheme);
    }
  }, [scene, loader, pmRemGenerator, themeId, gl]);

  return null;
}

export default HDREnvironment;
