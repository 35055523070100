import sphere from 'assets/3d-models/sphere.glb';
import { useAnimations, useGLTF, useProgress } from "@react-three/drei";
import { useEffect, useMemo } from 'react';

export default function Sphere({ loadingProgress }) {
  const { progress } = useProgress();
  const { nodes, scene, animations } = useGLTF(sphere);
  const { actions } = useAnimations(animations, scene);

  useMemo(() =>
      Object.values(nodes).forEach(obj => obj.isMesh && Object.assign(obj, { castShadow: true, receiveShadow: true })), [nodes]
  );

  useEffect(() => {
    loadingProgress.current = progress;
  }, [progress, loadingProgress]);

  useEffect(() => {
    actions.sphereRotation.play();
  });

  return <primitive object={scene} position={[0, 3.5, 0]} scale={[0.15, 0.15, 0.15]} />;
}
