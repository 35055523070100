import { Box, Reflector, useTexture } from '@react-three/drei';
import floorTextureBase from '../../../assets/textures/floorBase.jpeg';
import floorTextureNormal from '../../../assets/textures/floorNormal.jpeg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RepeatWrapping, Vector3 } from "three";
import { useFrame } from '@react-three/fiber';
import { useSpring } from '@react-spring/three';
import VolumetricLight from '../VolumetricLights/VolumetricLight';

function Scene() {
  const [floor, normal] = useTexture([floorTextureBase, floorTextureNormal]);
  floor.wrapS = RepeatWrapping;
  floor.wrapT = RepeatWrapping;
  normal.wrapS = RepeatWrapping;
  normal.wrapT = RepeatWrapping;
  floor.repeat.set(4, 4);
  normal.repeat.set(4, 4);

  const [vec] = useState(() => new Vector3());
  const ref = useRef();

  useFrame(state => {
    state.camera.position.lerp(vec.set(state.mouse.x * 14, 12, 20), 0.05);
    state.camera.lookAt(0, 0, 0);
  });

  const target = useRef();

  // ANIMATION
  const tangle = useRef(0);

  const toggle = useRef(false);

  const s1 = useMemo(() => {
    return [
      {
        position: [-12, 14, 0],
        intensity: 1,
        penumbra: 0.8,
        color: '#ffff99',
        angle: 0.3,
      },
      {
        position: [0, 12, 0],
        intensity: 1,
        penumbra: 0.8,
        color: '#ffc55f',
        angle: 0.3,
      },
      {
        position: [-20, 8, 4],
        intensity: 2,
        penumbra: 1,
        color: '#ff4b4b',
        angle: 0.4,
      },
    ];
  }, []);

  const s2 = useMemo(() => {
    return [
      {
        position: [12, 15, 10],
        intensity: 1,
        penumbra: 0.8,
        color: '#43d8c9',
        angle: 0.3,
      },
      {
        position: [8, 14, 4],
        intensity: 1,
        penumbra: 0.8,
        color: '#3498db',
        angle: 0.3,
      },
      {
        position: [18, 10, 2],
        intensity: 1.4,
        penumbra: 1,
        color: '#a887ff',
        angle: 0.4,
      },
    ];
  }, []);

  const s3 = useMemo(() => {
    return [
      {
        position: [10, 16, 0],
        intensity: 1,
        penumbra: 0.8,
        color: '#07ff00',
        angle: 0.01,
      },
      {
        position: [-8, 12, 0],
        intensity: 1,
        penumbra: 0.8,
        color: '#07ff00',
        angle: 0.01,
      },
      {
        position: [-8, 14, 4],
        intensity: 1,
        penumbra: 0.8,
        color: '#a887ff',
        angle: 0.3,
      },
    ];
  }, []);

  const [light1, setLight1] = useSpring(() => s1[2]);
  const [light2, setLight2] = useSpring(() => s2[2]);
  const [light3, setLight3] = useSpring(() => s3[1]);

  useEffect(() => {
    const t = setInterval(() => {
      if (toggle.current % 3 === 0) {
        setLight1.start(s1[1]);
        setLight2.start(s2[1]);
        setLight3.start(s3[2]);
      } else if (toggle.current % 3 === 1) {
        setLight1.start(s1[0]);
        setLight2.start(s2[0]);
        setLight3.start(s3[0]);
      } else {
        setLight1.start(s1[2]);
        setLight2.start(s2[2]);
        setLight3.start(s3[1]);
      }

      toggle.current += 1;
    }, 4000);

    return () => {
      clearInterval(t);
    };
  }, [s1, s2, s3, setLight1, setLight2, setLight3]);

  useFrame(() => {
    tangle.current += (Math.PI / 2) * 0.01;
    const targetPosition = new Vector3(
      1.5 * Math.cos(tangle.current * 2),
      Math.sin(tangle.current * 2) - 4,
      1.5 * Math.sin(tangle.current * 2)
    );

    if (target) {
      target.current.position.copy(targetPosition);
    }
  });

  return (
    <>
      <group ref={ref}>
        <Reflector
          receiveShadow
          resolution={1024}
          args={[256, 256]}
          mirror={0.4}
          mixBlur={8}
          mixStrength={1}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          blur={[400, 100]}
        >
          {(Material, props) => (
            <Material
              color="#cccccc"
              metalness={0.4}
              roughnessMap={floor}
              normalMap={normal}
              normalScale={[1, 1]}
              {...props}
            />
          )}
        </Reflector>
      </group>

      <Box ref={target} />

      <VolumetricLight penumbra={0.5} target={target} ref={light3} {...light3} />
      <VolumetricLight penumbra={0.5} target={target} ref={light2} {...light2} />
      <VolumetricLight penumbra={0.5} target={target} ref={light1} {...light1} />
    </>
  );
}

export default Scene;
