import { animated as a } from '@react-spring/three';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { Matrix4 } from 'three';
import { VolumetricSpotlight } from '../Shaders/volumetric-spotlight';

extend({ VolumetricSpotlight });

const VolumetricLight = forwardRef(function VolumetricLight(props, ref) {
  const vs = useRef();
  const spotlight = useRef();
  const { scene } = useThree();

  const {
    angle = 0.3,
    penumbra = 0.1,
    distance = 60,
    color,
    intensity,
    position,
    target,
  } = props;

  // INIT
  useEffect(() => {
    scene.add(spotlight.current.target);

    const geometry = vs.current.geometry;

    geometry.applyMatrix4(
      new Matrix4().makeTranslation(0, -geometry.parameters.height / 2, 0)
    );
    geometry.applyMatrix4(new Matrix4().makeRotationX(-Math.PI / 2));

    vs.current.material.uniforms.spotPosition.value = vs.current.position;

    spotlight.current.position.copy(vs.current.position);
  }, [scene, color, position]);

  useFrame(() => {
    spotlight.current.position.copy(vs.current.position);
    //todo fix this
    vs.current.material.uniforms.lightColor.value = spotlight.current.color;

    if (target && target.current) {
      vs.current.lookAt(target.current.position);
      spotlight.current.target.position.copy(target.current.position);
    }
  });

  const setRef = useCallback(
    function setRef(el) {
      vs.current = el;

      if (ref) {
        ref.current = el;
      }
    },
    [ref]
  );

  // maps spotlight angle to volume cylinder every frame
  // it would be better to do it on a need-to basis
  // but it doesn't play nice with react-spring
  useFrame(() => {
    const angle = spotlight.current.angle;

    vs.current.scale.set(6 * angle, 6 * angle, 1);
  });

  return (
    <>
      <a.spotLight
        castShadow
        ref={spotlight}
        intensity={intensity}
        angle={angle}
        penumbra={penumbra}
        distance={distance}
        color={color}
      />

      <a.mesh ref={setRef} position={position}>
        <a.coneGeometry args={[10, 40, 64, 30, 40, true]} attach="geometry" />

        <volumetricSpotlight
          attach="material"
          uniforms-lightColor-value={color}
          uniforms-attenuation-value={24}
          uniforms-anglePower-value={8}
        />
      </a.mesh>
    </>
  );
});

export default VolumetricLight;
