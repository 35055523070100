import Me from '../../../assets/images/photo_of_me_black_white.jpg';
import { useMemo, useState } from 'react';
import { TextureLoader } from 'three';
import { animated, config, useSpring } from '@react-spring/three';
import { HoverImageShader } from './Shaders/HoverImageShader';
import { Canvas, extend, useLoader } from "@react-three/fiber";
import './secondaryPortrait.css';
import { reflow } from '../../../utils/transition';
import { Transition } from 'react-transition-group';
import { Effects } from '@react-three/drei';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { WaterPass } from './Shaders/Waterpass';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
import classNames from 'classnames';
import { useTheme } from '../../../components/ThemeProvider';

// Makes these prototypes available as "native" jsx-string elements
extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  WaterPass,
  AfterimagePass,
  UnrealBloomPass,
  FilmPass,
});

const SecondaryPortrait = ({ className, delay, ...rest }) => {
  const { themeId } = useTheme();

  const [props] = useSpring(() => ({
    pos: [0, 0, 0],
    scale: [1, 1, 1],
    rotation: [0, 0, 0],
    config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 },
  }));

  function Image({ ...props }) {
    const [hovered, setHover] = useState(false);

    const pictureOfMe = useLoader(TextureLoader, Me);

    const texture = useMemo(() => {
      const clone = pictureOfMe.clone();
      // Required to render texture at all
      clone.needsUpdate = true;
      return clone;
    }, [pictureOfMe]);

    const { hoverValue } = useSpring({
      hoverValue: hovered ? 1 : 0,
      config: config.molasses,
    });

    return (
      <animated.mesh
        onPointerOver={e => setHover(true)}
        onPointerOut={e => setHover(false)}
        {...props}
      >
        <planeBufferGeometry attach="geometry" args={[5.2, 7]} />
        <animated.shaderMaterial
          attach="material"
          transparent
          args={[HoverImageShader]}
          uniforms-tex-value={texture}
          uniforms-hover-value={hoverValue}
        />
      </animated.mesh>
    );
  }

  return (
    <Transition appear in onEnter={reflow} timeout={delay}>
      <div
        aria-hidden
        className={classNames('portrait_alt', `'portrait_alt'--${themeId}`, className)}
        style={{ '--delay': delay }}
        {...rest}
      >
        <Canvas
          className="portrait__canvas"
          pixelRatio={window.devicePixelRatio || 1}
          camera={{ fov: 75, position: [0, 0, 7] }}
        >
          <Image {...props} />
          <Effects>
            <waterPass attachArray="passes" factor={0.2} />
            <filmPass attachArray="passes" args={[0.1, 0.15, 500, false]} />
          </Effects>
        </Canvas>
      </div>
    </Transition>
  );
};

export default SecondaryPortrait;
