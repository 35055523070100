import React from "react";
import Lottie from 'react-lottie-player';
import lottieSpinner from '../../assets/lottie/loading-spinner.json';

const Spinner = () => {
  return (
    <Lottie
      loop
      animationData={lottieSpinner}
      play
      style={{ width: 200, height: 200 }}
    />
  );
};

export default Spinner;