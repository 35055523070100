import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Intro from './Intro/Intro';
import ProjectSummary from './ProjectSummary/ProjectSummary';
import Profile from './Profile/Profile';
import LightShow from "../../components/LightShow/LightShow";
import Footer from 'components/Footer';
import Spinner from "../../components/Spinner/Spinner";
import { useInterval, usePrefersReducedMotion, useRouteTransition } from "hooks";
import bbpLandingPage from 'assets/images/bbp-scheduler-large.png';
import bbpLandingPageLarge from 'assets/images/bbp-scheduler-large.png';
import bbpLandingPagePlaceholder from 'assets/images/bbp-scheduler-sm.png';
import landingPageTexture from 'assets/images/landing-page-dark.png';
import landingPageTextureLarge from 'assets/images/landing-page-dark.png';
import landingPageTexturePlaceholder from 'assets/images/landing-page-dark-sm.png';
import deviceModelsTexture from 'assets/images/justcook.png';
import deviceModelsTextureLarge from 'assets/images/justcook.png';
import deviceModelsTexturePlaceholder from 'assets/images/justcook-Placeholder.png';
import deviceModelsTexture2 from 'assets/images/justcook2.png';
import deviceModelsTextureLarge2 from 'assets/images/justcook2.png';
import deviceModelsTexturePlaceholder2 from 'assets/images/justcook2-Placeholder.png';
import dttTexture from 'assets/images/techStack.jpg';
import dttTextureLarge from 'assets/images/techStack.jpg';
import dttTexturePlaceholder from 'assets/images/techStack-Placeholder.jpg';
import iphone11 from 'assets/3d-models/iphone-11.glb';
import macbookPro from 'assets/3d-models/macbook-pro.glb';
import LoadingOverlay from "react-loading-overlay";
import './index.css';

const disciplines = ['Software Engineer', 'Creator'];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  const projectThree = useRef();
  const projectFour = useRef();
  const about = useRef();
  const outro = useRef();
  const outroLoadingProgress = useRef(0);
  const prefersReducedMotion = usePrefersReducedMotion();
  const [loadComplete, setLoadComplete] = useState(false);

  useEffect(() => {
    const revealSections = [intro, projectOne, projectTwo, projectThree, projectFour, about, outro];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, projectOne, projectTwo, projectThree, projectFour, about, outro];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  useInterval(() => {
    if (outroLoadingProgress.current === 100 && document.readyState === "complete") {
      setLoadComplete(true);
    }
  }, !loadComplete ? 1000 : null);

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0,0,0,0.5)',
          width: window.innerWidth,
          height: window.innerHeight,
          position: 'fixed',
          top: 0,
          left: 0
        })
      }}
      active={!loadComplete}
      spinner={<Spinner />}
      text='Loading...'>
      <div className="home">
        <Helmet>
          <title>Toma Chan | Portfolio</title>
          <meta
            name="description"
            content="Portfolio of Toma Chan, a student in Software Technology Engineering at VIA UC."
          />
          <link rel="prefetch" href={iphone11} as="fetch" />
          <link rel="prefetch" href={macbookPro} as="fetch" />
        </Helmet>
        <Intro
          id="intro"
          sectionRef={intro}
          disciplines={disciplines}
          scrollIndicatorHidden={scrollIndicatorHidden}
          loadComplete={loadComplete}
        />
        <ProjectSummary
          id="project-1"
          sectionRef={projectOne}
          visible={visibleSections.includes(projectOne.current)}
          index={1}
          title="Bachelor project"
          description="The final project from my educational program."
          buttonText="View Project"
          buttonLink="/projects/bachelor-project"
          model={{
            type: 'laptop',
            alt: 'The Semester Project Page',
            textures: [
              {
                src: bbpLandingPage,
                srcSet: `${bbpLandingPage} 800w, ${bbpLandingPageLarge} 1440w`,
                placeholder: bbpLandingPagePlaceholder,
              },
            ],
          }}
        />
        <ProjectSummary
          id="project-2"
          sectionRef={projectTwo}
          visible={visibleSections.includes(projectTwo.current)}
          index={2}
          title="Semester project"
          description="A project that aimed at examining the concepts of Cloud Computing and DevOps together with their strategic considerations."
          buttonText="View Project"
          buttonLink="/projects/semester-project"
          model={{
            type: 'laptop',
            alt: 'The Semester Project Page',
            textures: [
              {
                src: landingPageTexture,
                srcSet: `${landingPageTexture} 800w, ${landingPageTextureLarge} 1440w`,
                placeholder: landingPageTexturePlaceholder,
              },
            ],
          }}
        />
        <ProjectSummary
          id="project-2"
          alternate
          sectionRef={projectThree}
          visible={visibleSections.includes(projectThree.current)}
          index={3}
          title="My first Android app"
          description="Application developed for Android using Java, MVVM, Retrofit, Room, Hilt, RxJava and more."
          buttonText="Check it out on GitHub"
          buttonLink="https://github.com/rexizam/JustCook"
          model={{
            type: 'phone',
            alt: 'Image of the UI',
            textures: [
              {
                src: deviceModelsTexture2,
                srcSet: `${deviceModelsTexture2} 254w, ${deviceModelsTextureLarge2} 508w`,
                placeholder: deviceModelsTexturePlaceholder2,
              },
              {
                src: deviceModelsTexture,
                srcSet: `${deviceModelsTexture} 254w, ${deviceModelsTextureLarge} 508w`,
                placeholder: deviceModelsTexturePlaceholder,
              },
            ],
          }}
        />
        <ProjectSummary
          id="project-3"
          sectionRef={projectFour}
          visible={visibleSections.includes(projectFour.current)}
          index={4}
          title="My technology stack"
          description="Here you can find out more about the technologies I have learned so far."
          buttonText="Learn more"
          buttonLink="/projects/dtt"
          model={{
            type: 'laptop',
            alt: 'Technology Stack Page',
            textures: [
              {
                src: dttTexture,
                srcSet: `${dttTexture} 800w, ${dttTextureLarge} 1440w`,
                placeholder: dttTexturePlaceholder,
              },
            ],
          }}
        />
        <Profile
          sectionRef={about}
          visible={visibleSections.includes(about.current)}
          id="about"
        />
        <LightShow
          sectionRef={outro}
          visible={visibleSections.includes(outro.current)}
          id={'outro'}
          loadingProgress={outroLoadingProgress}
        />
        <Footer />
      </div>
    </LoadingOverlay>
  );
};

export default Home;
